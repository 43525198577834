<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

import LayoutHeader from '@/components/LayoutHeader.vue'
import LayoutBreadcrumb from '@/components/LayoutBreadcrumb.vue'
import LayoutFooter from '@/components/LayoutFooter.vue'

import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { use as usePageLevel } from '@/stores/pageLevel'
import { usePageLevelInit } from '@/composables/pageLevel'

import type { MessageContext } from '@intlify/core-base'
const { children } = usePageLevelInit()

const page = usePageLevel()
const { pageLevel, level } = storeToRefs(page)

const route = useRoute()

const { t } = useI18n({
  messages: {
    ja: {
      title: (ctx: MessageContext) => {
        const { type, buildingType, prefecture, city, town } = ctx.values
        if (prefecture || city || town) {
          return `${ctx.linked(
            'currentLocation',
            ctx.values
          )}内のエリアから${type}${buildingType}を探す`
        }
        if (level.value == 'buildingType') {
          // XXX
          return `都道府県から${type}${buildingType}を探す`
        }
        if (level.value == 'type') {
          return `${type}物件のタイプを選ぶ`
        }
        return ''
      },
      description: (ctx: MessageContext) => {
        const { type, buildingType = '', level, nextLevels = [level] } = ctx.values
        const loc = ctx.linked('currentLocation', ctx.values)
        return `該当${(nextLevels as string[])
          .map((level: string) => ctx.linked('level.' + level))
          .join('、')}を押すと${loc && loc + '内の'}${type}${buildingType}物件が一覧できます。`
      },
    },
  },
})

const description = computed(() =>
  t('description', {
    ...pageLevel.value,
    nextLevels: children.value ? Object.keys(children.value) : undefined,
  })
)

useHead({
  title: () => t('title', pageLevel.value),
  meta: [
    { name: 'description', content: () => description.value },
    { name: 'og:title', content: () => t('title', pageLevel.value) },
    { name: 'og:description', content: () => description.value },
  ],
  link: [
    {
      rel: 'canonical',
      href: import.meta.env.VITE_SEO_ORIGIN + route.path,
    },
  ],
})
</script>
<template>
  <LayoutHeader />
  <LayoutBreadcrumb />
  <main class="frame">
    <h1 class="w6" v-if="pageLevel">{{ t('title', pageLevel) }}</h1>
    <template v-for="(groups, level) in children" :key="level">
      <h2>
        {{ t('description', { ...pageLevel, nextLevels: [level] }) }}
      </h2>
      <div class="group" v-for="(pages, group) in groups" :key="group">
        <h3 v-if="Object.keys(groups).length > 1">
          {{ group == 'null' ? 'その他の地域' : group }}
        </h3>
        <nav class="links u-row">
          <RouterLink v-for="{ path, name } of pages" :to="'/' + path" :key="path">
            {{ name }}
          </RouterLink>
        </nav>
      </div>
    </template>
  </main>
  <LayoutFooter />
</template>
<style scoped>
main {
  padding-bottom: 40px;
}
main h1 {
  font-size: 20px;
  margin-top: 42px;
}
main h2 {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 300;
  color: var(--cl-black-60);
}
main .group {
  margin: 24px 0;
}
main .group h3 {
  font-size: 16px;
  font-weight: 600;
}
main .group .links {
  flex-wrap: wrap;
  margin-right: -24px;
}
main .group .links a {
  padding: 0 16px;
  height: 46px;
  line-height: 46px;
  font-weight: 600;
  border: 1px solid var(--cl-black);
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(51, 51, 51, 0.1);
  margin-top: 24px;
  margin-right: 24px;
  width: 30%;
}
@media (max-width: 768px) {
  main .group .links {
    margin-right: 0;
  }
  main .group .links a {
    margin-right: 0;
    width: 100%;
  }
}
.frame,
:deep(.frame) {
  width: 1060px;
  max-width: 90%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .frame {
    margin: 0 16px;
    width: auto;
    max-width: unset;
  }
}
</style>
<style>
body {
  overflow-y: auto;
}
h2,
h3,
h4 {
  font-size: unset;
  font-weight: unset;
  font-style: unset;
}
</style>

<i18n lang="yaml">
en:
  level:
    prefecture: 'Prefecture | Prefectures'
    city: 'City | Cities'
    buildingType: 'Home type | Home types'
  buildingType:
    condo: 'マンション・アパート'
ja:
  level:
    prefecture: '都道府県'
    city: '市区町村'
    buildingType: '物件種別'
</i18n>
